import {
  Track,
  track as _track,
  useTracking as _useTracking,
  TrackingProp,
} from 'react-tracking';
import _mixpanel, { Mixpanel as MixpanelBrowser } from 'mixpanel-browser';

export const events = {
  USER_LOGIN: 'USER_LOGIN',
  USER_LOGIN_FAILED: 'USER_LOGIN_FAILED',
  USER_LOGOUT: 'USER_LOGOUT',
  PAGE_VISIT: 'PAGE_VISIT',
  USER_CLICK: 'USER_CLICK',
  USER_VIEWED: 'USER_VIEWED',
  TRACK_LIST_EDIT: 'TRACK_LIST_EDIT',

  TRACK_LIBRARY_CREATE: 'TRACK_LIBRARY_CREATE',
  TRACK_LIBRARY_DELETE: 'TRACK_LIBRARY_DELETE',
  TRACK_BOARD_DELETE: 'TRACK_BOARD_DELETE',
  TRACK_LIBRARY_EDIT: 'TRACK_LIBRARY_EDIT',
  TRACK_LIBRARY_CREATE_RELEASE: 'TRACK_LIBRARY_CREATE_RELEASE',
  NPS_SCORE: 'NPS_SCORE',
  BALANCE_DOWNLOAD_CSV: 'BALANCE_DOWNLOAD_CSV',
  BALANCE_DOWNLOAD_CSV_FAILURE: 'BALANCE_DOWNLOAD_CSV_FAILURE',
  BALANCE_DOWNLOAD_PDF: 'BALANCE_DOWNLOAD_PDF',
  BALANCE_REQUEST_WITHDRAWAL: 'BALANCE_REQUEST_WITHDRAWAL',
  BALANCE_SUCCESS_WITHDRAWAL: 'BALANCE_SUCCESS_WITHDRAWAL',
  BALANCE_CANCEL_WITHDRAWAL: 'BALANCE_CANCEL_WITHDRAWAL',
  BALANCE_OPEN_WITHDRAWAL_MODAL: 'BALANCE_OPEN_WITHDRAWAL_MODAL',
  BALANCE_CLOSE_WITHDRAWAL_MODAL: 'BALANCE_CLOSE_WITHDRAWAL_MODAL',
  BALANCE_CLOSE_CONFIRMATION_MODAL: 'BALANCE_CLOSE_CONFIRMATION_MODAL',
  BALANCE_CLOSE_PENDING_MODAL: 'BALANCE_CLOSE_PENDING_MODA',
  AUDIO_UPLOAD: 'AUDIO_UPLOAD',
  STATS_TESTING_NAV_CLICK: 'STATS_TESTING_NAV_CLICK',
  STATS_TESTING_AMAZE_CLICK: 'STATS_TESTING_AMAZE_CLICK',
  STATS_TESTING_CLOSE_WITHOUT_TEST: 'STATS_TESTING_CLOSE_WITHOUT_TEST',
  STATS_MAP_CHART_INTERACTION: 'STATS_MAP_CHART_INTERACTION',
  WAVEALYZER_DISMISS_ALL: 'WAVEALYZER_DISMISS_ALL',
  WAVEALYZER_DISMISS_INDIVDUAL: 'WAVEALYZER_DISMISS_INDIVDUAL',
  BROADCAST_MONITORING_INTERESTED: 'BROADCAST_MONITORING_INTERESTED',
  BROADCAST_MONITORING_GEMA_ACTIVATE: 'BROADCAST_MONITORING_GEMA_ACTIVATE',
  BROADCAST_MONITORING_GEMA_UPLOAD_SUCCCESS:
    'BROADCAST_MONITORING_GEMA_UPLOAD_SUCCCESS',
  BROADCAST_MONITORING_WORK_SEARCH: 'BROADCAST_MONITORING_WORK_SEARCH',
  BROADCAST_MONITORING_WORK_SEARCH_SELECT:
    'BROADCAST_MONITORING_WORK_SEARCH_SELECT',
  BROADCAST_MONITORING_WORK_SEARCH_REIMPORT:
    'BROADCAST_MONITORING_WORK_SEARCH_REIMPORT',
  BROADCAST_MONITORING_GEMA_UPLOAD_FAILURE:
    'BROADCAST_MONITORING_GEMA_UPLOAD_FAILURE',
  WORKS_FILL_TRACK_METADATA_CTA: 'WORKS_FILL_TRACK_METADATA_CTA',
  PASSWORD_RESET: 'PASSWORD_RESET',

  // Subscriptions
  SUBSCRIPTIONS_CTA: 'SUBSCRIPTIONS_CTA',
  SUBSCRIPTIONS_LAUNCH_OFFER_CTA: 'SUBSCRIPTIONS_LAUNCH_OFFER_CTA',
  SUBSCRIPTIONS_MODAL_CLOSE: 'SUBSCRIPTIONS_MODAL_CLOSE',
  PAYMENT_SUCCESS_MODAL_OPEN: 'PAYMENT_SUCCESS_MODAL_OPEN',
  PAYMENT_ERROR_MODAL_OPEN: 'PAYMENT_ERROR_MODAL_OPEN',
  PAYMENT_SWITCH: 'PAYMENT_SWITCH',
  REACTIVATE_SUCCESS_MODAL_OPEN: 'REACTIVATE_SUCCESS_MODAL_OPEN',
  SUBSCRIPTION_CANCELLATION: 'SUBSCRIPTION_CANCELLATION',
  SUBSCRIPTION_REACTIVATION: 'SUBSCRIPTION_REACTIVATION',
  FINISH_ACCT_SETUP_BTN: 'FINISH_ACCT_SETUP_BTN',

  // Work Declarations
  WORKS_DECLARE_ERROR: 'WORKS_DECLARE_ERROR',
  WORKS_DECLARE_SUCCESS: 'WORKS_DECLARE_SUCCESS',

  // Ts & Cs
  TERMS_UPDATE_CLOSE: 'TERMS_UPDATE_CLOSE',
  TERMS_UPDATE_AGREE: 'TERMS_UPDATE_AGREE',
  FULL_TERMS_UPDATE_READ: 'FULL_TERMS_UPDATE_READ',

  //Release flow navigating away prompt
  RELEASE_NAV_PROMPT: 'RELEASE_NAV_PROMPT',

  //Delete authors, artist or contributors
  DELETE_PARTICIPANT: 'DELETE_PARTICIPANT',

  AUDIO_DOWNLOAD: 'AUDIO_DOWNLOAD',

  // Smart Links
  SMART_LINK_COPIED: 'SMART_LINK_COPIED',

  //Lyrics
  LYRICS_INTRO_MODAL_OPENED: 'LYRICS_INTRO_MODAL_OPENED',
  LYRICS_INTRO_MODAL_GET_STARTED_CLICKED:
    'LYRICS_INTRO_MODAL_GET_STARTED_CLICKED',
  LYRICS_MODAL_OPENED: 'LYRICS_MODAL_OPENED',
  LYRICS_TAB_VIEW: 'LYRICS_TAB_VIEW',

  // Copy track meta data
  COPY_TRACK_META_DATA: 'COPY_TRACK_META_DATA',
  COPY_TRACK_META_DATA_CANCEL_MODAL: 'COPY_TRACK_META_CANCEL_MODAL',

  // Typeform
  TYPEFORM_SUBMIT: 'TYPEFORM_SUBMIT',

  RELEASE_SEARCH: 'RELEASE_SEARCH',
  TRACK_SEARCH: 'TRACK_SEARCH',

  // Upload Certificate
  UPLOAD_CERTIFICATE_MODAL_OPENED: 'UPLOAD_CERTIFICATE_MODAL_OPENED',
  UPLOAD_CERTIFICATE_MODAL_CLOSE: 'UPLOAD_CERTIFICATE_MODAL_CLOSE',
  UPLOAD_CERTIFICATE_MODAL_DOWNLOAD: 'UPLOAD_CERTIFICATE_MODAL_DOWNLOAD',
  UPLOAD_CERTIFICATE_TAB_DOWNLOAD: 'UPLOAD_CERTIFICATE_TAB_DOWNLOAD',

  // Promo Partners
  PROMO_PARTNERS_MODAL_OPENED: 'PROMO_PARTNERS_MODAL_OPENED',
  PROMO_PARTNERS_MODAL_CLOSED: 'PROMO_PARTNERS_MODAL_CLOSED',

  // Bio page
  BIO_PAGE_CREATED: 'BIO_PAGE_CREATED',
  BIO_PAGE_LINK_COPIED: 'BIO_PAGE_LINK_COPIED',
  BIO_PAGE_UPDATED: 'BIO_PAGE_UPDATED',
  BIO_PAGE_DELETED: 'BIO_PAGE_DELETED',

  //Share audio
  SHARE_AUDIO_LINK_COPIED: 'SHARE_AUDIO_LINK_COPIED',
  UNSHARE_AUDIO_LINK: 'UNSHARE_AUDIO_LINK',

  //Track idea
  TRACK_IDEA_UPDATED: 'TRACK_IDEA_UPDATED',
  TRACK_BOARD_LOAD_MORE: 'TRACK_BOARD_LOAD_MORE',
  TRACK_BOARD_EDIT_TRACK: 'TRACK_BOARD_EDIT_TRACK',
  TRACK_BOARD_SEARCH: 'TRACK_BOARD_SEARCH',

  //Referral Program
  REFERRAL_LINK_COPIED: 'REFERRAL_LINK_COPIED',
  REFERRAL_CODE_COPIED: 'REFERRAL_CODE_COPIED',

  // Royalties Dashboard
  ROYALTIES_INCOME_SORTED_BY: 'ROYALTIES_INCOME_SORTED_BY',

  // Headway interaction
  HEADWAY_NEWS_INTERACTION: 'HEADWAY_NEWS_INTERACTION',
};

export const peopleProperties = {
  BALANCE_WITHDRAWAL_TOTAL: 'BALANCE_WITHDRAWAL_TOTAL',
  IS_INTERNAL_USER: 'IS_INTERNAL_USER',
  IS_GEMA_USER: 'IS_GEMA_USER',
  MUSIC_HUB_USER_ID: 'MUSIC_HUB_USER_ID',
  NUMBER_OF_BIO_PAGES: 'NUMBER_OF_BIO_PAGES',
  SUBSCRIPTION_PLAN: 'SUBSCRIPTION_TYPE',
  SUBSCRIPTION_STATUS: 'SUBSCRIPTION_STATUS',
  SUBSCRIPTION_PAYMENT_METHOD: 'SUBSCRIPTION_PAYMENT_METHOD',
  SUBSCRIPTION_BILLING_CYLCE: 'SUBSCRIPTION_BILLING_CYCLE',
  // Experiments
  PRICING_EXPERIMENT_VARIANT: 'PRICING_EXPERIMENT_VARIANT',
};

export interface TrackingData {
  event: keyof typeof events;
  entity_id?: string;
  extra_entity_id?: string;
  url?: string;
  search?: string;
  element?: string;
  value?: string | number;
  issuer?: string;
  reasons?: string[];
  interaction_type?: string;
  type?: string;
  state?: Record<string, unknown> | object;
}

export const track: Track<TrackingData> = _track;
export const useTracking = (): TrackingProp<TrackingData> =>
  _useTracking<TrackingData>();

_mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN as string);

class Mixpanel {
  isIdentified = false;
  base: MixpanelBrowser = _mixpanel;

  get isDisabled(): boolean {
    return process.env.REACT_APP_MIXPANEL_DISABLED === 'true';
  }

  setIsIdentified(isIdentified: boolean) {
    this.isIdentified = isIdentified;
  }

  identify(userId?: string) {
    if (this.isDisabled || !userId) {
      this.setIsIdentified(false);
      return;
    }
    this.setIsIdentified(true);
    return _mixpanel.identify(userId);
  }

  increment(event: keyof typeof peopleProperties, value: number) {
    if (!this.isIdentified || this.isDisabled) {
      return;
    }
    return _mixpanel.people.increment(event, value);
  }

  setPeopleProperty(
    event: keyof typeof peopleProperties,
    value?: string | number | boolean
  ) {
    if (!this.isIdentified || this.isDisabled) {
      return;
    }
    return _mixpanel.people.set(event, value);
  }

  track({ event, ...properties }: TrackingData) {
    if (this.isDisabled) {
      return;
    }
    return _mixpanel.track(event, properties);
  }
}

export const mixpanel = new Mixpanel();
